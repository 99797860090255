import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../core/app.store";
import Pagination from "../../components/pagination/pagination.view";
import UrlCollect from "../../common/url-collect";
import {
  APIUrlDefault, DomainUserSide,
  NotificationMessageType,
  NotificationPosition,
} from "../../utils/configuration";
import MenuRightView from "./menu-right/menu-right.view";
import "@geedmo/yamm/src/yamm.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./list.scss";
import * as newsActions from "../../redux/store/news/news.store";
import dateformat from "dateformat";
import {useHistory} from "react-router-dom";
import HomeAside from "../home/aside/aside.view";

const firstLoad = true;

function ListNewsView(props) {
  const { showLoading } = props;
  const history = useHistory();

  const [keyword, setKeyword] = useState([]);

  const [currentPageIndexDoing, setCurrentPageIndexDoing] = useState(1);
  const currentPageSizeDoing = 4;
  const [totalItemCountDoing, setTotalItemCountDoing] = useState(0);

  const [pageCountDoing, setPageCountDoing] = useState();
  const [listHotNews, setHotNewsModel] = useState([]);
  const [listNews, setListNews] = useState([]);
  const [hotNews, setHotNews] = useState({});

  useEffect(() => {
    onGetData();
  }, [firstLoad]);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      onGetListHotNews({
          pageIndex: 1,
          pageSize: 5,
      }),
      onGetListNews({
          pageIndex: currentPageIndexDoing,
          pageSize: currentPageSizeDoing,
      }),
    ])
        .then((res) => {
          showLoading(false);
        })
        .catch((err) => {
          showLoading(false);
        });
  };

    const setTextSearch = (event) => {
      setKeyword(event.target.value);
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            onGetListNews({
                    keyword:keyword,
                    pageIndex: 1,
                    pageSize: currentPageSizeDoing,
                }
            );
        }
    };

    const handleClickSearchIcon = () => {
        onGetListNews({
                keyword:keyword,
                pageIndex: 1,
                pageSize: currentPageSizeDoing,
            }
        );
    };

    const onGetListNews = (data) => {
        return new Promise((resolve, reject) => {
            newsActions.GetListNews(data).then(
                (res) => {
                    setListNews(
                        res &&
                        res.content &&
                        res.content.items
                        && res.content.items.length > 0 ? res.content.items: []
                    );
                    setTotalItemCountDoing(
                        res && res.content && res.content.totalItemCount
                            ? res.content.totalItemCount
                            : 0
                    );
                    setPageCountDoing(
                        res && res.content && res.content.pageCount
                            ? res.content.pageCount
                            : 0
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

  const onGetListHotNews = (data) => {
    return new Promise((resolve, reject) => {
      newsActions.GetListHotNews(data).then(
          (res) => {
            setHotNewsModel(
                res &&
                res.content &&
                res.content.items
                && res.content.items.length > 0 ? res.content.items: []
            );
            resolve(res);
          },
          (err) => {
            reject(err);
          }
      );
    });
  }

  const onPagingClick = (data, isClearSearch = false) => {
        onGetListNews({
                pageIndex: data.pageIndex,
                pageSize: currentPageSizeDoing,
            }
        );
  };

  return (
      <div className="container news reset-news-bg">
        <div className="community-opinion mb-4">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
              <nav className="yamm navbar navbar-expand header-doing">
                <h6 className="navbar-brand title mb-0">
                  TIN TỨC CHUNG
                </h6>
                <div id="list-news-header-box" className="news-list dropdown yamm-fw">
                    <input
                        type="text"
                        name="search"
                        id="search"
                        className="input-search float-right"
                        onChange={setTextSearch}
                        onKeyPress={handleKeyPress}
                    />

                  <FontAwesomeIcon
                      icon={faSearch}
                      className="icon-search dropdown-toggle"
                      onClick={handleClickSearchIcon}
                  />
                </div>
              </nav>
                <div className="other-news">
                    {Array.isArray(listNews) && listNews.length >0 ? (
                        listNews.map((item,index) => (
                            index === 0 ? (
                                <div className="hot-new mb-3">
                                    <div className="new-image-home">
                                        <img
                                            src={APIUrlDefault + item.image_Url}
                                            onError={(e) =>
                                                (e.target.src = "/images/no_image.png")
                                            }
                                            alt={item.title}
                                        />
                                        <div className="middle" onClick={() =>history.push(`${UrlCollect.NewsDetail}/${item.id}`)}>
                                          <div className="text">Xem thêm</div>
                                        </div>
                                    </div>
                                    <div className="hot-new-info" style={{padding: '8px 10px 14px'}}>
                                      <a href={"/tin-tuc-chi-tiet/" + item.id} className="new-title h4">{item.title}</a>
                                      <div className="new-date">{dateformat(item.created_date, "dd/mm/yyyy")}</div>
                                      <p className="new-desc" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                    </div>
                                </div>
                        ):(
                            <div key={`news-all-home-item-${index}`} className="card mb-3 item-wrapper new-item list-other-news ">
                                <div className="row no-gutters">
                                  <div className="col-sm-5 news-item-image">
                                    <img
                                        src={APIUrlDefault + item.image_Url}
                                        onError={(e) =>
                                            (e.target.src = "/images/no_image.png")
                                        }
                                        alt={item.title}
                                    />
                                    <div className="middle" onClick={() =>history.push(`${UrlCollect.NewsDetail}/${item.id}`)}>
                                      <div className="text">Xem thêm</div>
                                    </div>
                                  </div>
                                  <div className="col-sm-7 news-item-body">
                                    <div className="card-body">
                                        <h5 className="card-title news-item-title" onClick={() =>history.push(`${UrlCollect.NewsDetail}/${item.id}`)}>{item.title}</h5>
                                        {item.created_date && <div className="news-item-date">{dateformat(item.created_date, "dd/mm/yyyy")}</div>}
                                        <p className="card-text news-item-desc">{item.description}</p>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        )))
                    ):(
                        <div className="no-data-for-display">Chưa có dữ liệu để hiển thị!</div>
                    )}
                </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
              <div className="header-result">
                <div className="title">
                  <span className="text-uppercase text-white">
                    TIN MỚI NHẤT
                  </span>
                </div>
              </div>


              <div className="list-hot-news my-3"> 
                {listHotNews && listHotNews.length >0 ? (
                    listHotNews.map((item,index) => (
                      index < 5 &&
                        <div key={`news-hot-home-item-${index}`} className="card mb-3 item-wrapper" style={{width: '100%'}}>
                          <div className="row no-gutters">
                            <div className="col-lg-5 news-item-image">
                              <img
                                  src={APIUrlDefault + item.image_Url}
                                  onError={(e) =>
                                      (e.target.src = "/images/no_image.png")
                                  }
                                  alt={item.title}
                              />
                              <div className="middle" onClick={() =>history.push(`${UrlCollect.NewsDetail}/${item.id}`)}>
                                <div className="text">Xem thêm</div>
                              </div>
                            </div>
                            <div className="col-lg-7 news-item-body">
                              <div className="card-body">
                                  <h5 className="card-title news-item-title" onClick={() =>history.push(`${UrlCollect.NewsDetail}/${item.id}`)}>{item.title}</h5>
                                  {item.created_date && <div className="news-item-date">{dateformat(item.created_date, "dd/mm/yyyy")}</div>}
                                  <p className="card-text news-item-desc">{item.description}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                    ))
                ):(
                    <div className="new-item">Chưa có dữ liệu để hiển thị!</div>
                )}
                  {/* <MenuRightView /> */}
                  <HomeAside/>
              </div>

              
            </div>

          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8 d-none d-lg-block">
              {listNews && listNews.length > 0 && (
                  <div className="list-footer">
                    <div
                        className={`text-center text-result ${
                            pageCountDoing && pageCountDoing > 1 ? "float-left" : ""
                        }`}
                    >
                      <span>Có {totalItemCountDoing} kết quả</span>
                    </div>
                    {pageCountDoing && pageCountDoing > 1 && (
                        <div className="float-right">
                          <Pagination
                              totalItems={totalItemCountDoing}
                              currentIndex={currentPageIndexDoing}
                              pageSize={currentPageSizeDoing}
                              onClick={(pageIndex) => {
                                onPagingClick({
                                  pageIndex: pageIndex
                                });
                              }}
                          />
                        </div>
                    )}
                  </div>
              )}
            </div>
          </div>
        </div>
      </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
          showLoading: appActions.ShowLoading,
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListNewsView);
