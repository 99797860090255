import React from "react";
export default function BannerView(props) {
    return (
        <header className="header">
            {/* <div className="container header-banner" id="banner"> */}
                {/* <div className="row">
                    <div className="col-md-6">
                        <div className="logo d-flex">
                            <a href="/">
                                <img
                                    className="logo"
                                    src={"/images/logo-ctech.png"}
                                    alt="Logo"
                                    height="80"
                                />
                            </a>
                            <div className="header-title">
                                <div className="title1">CỤC CẢNH SÁT PCCC VÀ</div>
                                <div>CỨU NẠN CỨU HỘ</div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <img src={"images/banner-pccc.png"}></img>
            {/* </div> */}
        </header>
    );
}
