import React from "react";
import "./aside.scss";

const HomeAside = () => {
  return (
    <aside>
      <div className="block">
        <a href="http://canhsatpccc.gov.vn/ArticlesList/tabid/192/cateid/1172/language/vi-VN/default.aspx" target="_blank">
          <img src={require("./aside_thongcaobaochi.jpg")}/>
        </a>
      </div><div className="block">
        <a href="http://canhsatpccc.gov.vn/ArticlesList/tabid/192/cateid/1228/language/vi-VN/default.aspx" target="_blank">
          <img src={require("./aside_kyniem60nam.png")}/>
        </a>
      </div><div className="block">
        <a href="http://canhsatpccc.gov.vn/ArticlesList/tabid/192/cateid/1233/language/vi-VN/default.aspx" target="_blank">
          <img src={require("./aside_thongtinmuasamcong.jpg")}/>
        </a>
      </div><div className="block">
        <a href="http://canhsatpccc.gov.vn/ArticlesList/tabid/192/cateid/1234/language/vi-VN/default.aspx" target="_blank">
          <img src={require("./aside_trienlam2023.jpg")}/>
        </a>
      </div>
      <div className="block">
        <img src={require("./aside-hot-line.png")}/>
      </div>
      <div className="block">
        <img src={require("./aside-citizen-report.jpg")}/>
      </div>
      <div className="block">
        <img src={require("./aside-reporting-system.jpg")}/>
      </div>
    </aside>
  );
}


export default HomeAside;
