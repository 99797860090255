import React, { useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./contact.scss";
import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../utils/configuration";
import * as viVN from "../../languages/vi-VN.json";
import * as contactAction from "../../redux/store/contact/contact.store";
import viLang from '../../languages/vi';

export default function ContactDesktopView(props) {
  const [tel, setTel] = useState("");

  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearErrors,
  } = useForm({ mode: "onBlur" });

  const onCheckTel = (data) => {
    if (!data) setError("mobilePhone", { type: "required" });
    else if (data.toString().length < 10)
      setError("mobilePhone", { type: "pattern" });
    else clearErrors(["mobilePhone"]);
    setTel(data);
  };

  const onSubmitContact = async (data) => {
    if (!data || onCheckTel(tel)) return;
    await contactAction
      .Contact({
        name: data.fullName,
        phoneNumber: data.mobilePhone,
        email: data.email,
        message: data.content,
      })
      .then(
        (res) => {
          if (res && res.content) {
            ShowNotification(
              viVN.Success.ContactToAdminSuccess,
              NotificationMessageType.Success
            );
            clearDataForm();
          }
        },
        (err) => {
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
  };

  const handleOnChangeFullName = (e) => {
    if (e.currentTarget.value) {
      let wsRegex = /^\s*\s*$/;
      e.currentTarget.value = e.currentTarget.value.replace(wsRegex, "");
    }
  };

  const handleOnChangeEmail = (e) => {
    if (e.currentTarget.value) {
      let wsRegex = /^\s*|\s*$/g;
      e.currentTarget.value = e.currentTarget.value.replace(wsRegex, "");
    }
  };

  const clearDataForm = () => {
    document.getElementById("formContact").reset();
    setTel("+84");
  };

  return (
    <div id="lien-he" className="pt-3 pt-md-3 pt-lg-5 pb-3 pb-md-3 pb-lg-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 border-right">
            <h5>THÔNG TIN LIÊN HỆ</h5>
            <h6>{viLang.footer_titleName.toLocaleUpperCase()}</h6>
            <span>
              {viLang.footer_address}
            </span>

            <div className="row">
              <div className="col-12 col-sm-12 colmd-5 col-lg-5">
                Hotline: <a href={`tel:${viLang.contact_hotline}`}>{viLang.contact_hotline}</a>
              </div>
              <div className="col-12 col-sm-12 colmd-7 col-lg-7">
                Email:{" "}
                <a href={`mailto:${viLang.contact_email}`}>
                  {viLang.contact_email}
                </a>
              </div>
              <div className="col-12 col-sm-12 colmd-6 col-lg-6">
                Website:{" "}
                <a href={viLang.contact_website}>{viLang.contact_website}</a>
              </div>
            </div>

            <h5 className="mt-3">NỘI DUNG</h5>
            <form id="formContact" onSubmit={handleSubmit(onSubmitContact)}>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">
                  Họ tên<span className="required"></span>
                </label>
                <div className="col-lg-8">
                  <input
                    ref={register({
                      required: true,
                    })}
                    type="text"
                    name="fullName"
                    className="form-control"
                    placeholder="Họ tên"
                    onChange={handleOnChangeFullName}
                  />
                  {errors.fullName && errors.fullName.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">
                  Số điện thoại<span className="required"></span>
                </label>
                <div className="col-lg-8">
                  <PhoneInput
                    country={"vn"}
                    value={tel}
                    onChange={onCheckTel}
                    onBlur={() => onCheckTel(tel)}
                    placeholder="Số điện thoại"
                    autoFormat={false}
                    enableSearch={true}
                    countryCodeEditable={false}
                  />
                  <input
                    ref={register({
                      required: true,
                      pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
                    })}
                    type="hidden"
                    name="mobilePhone"
                    value={tel}
                    onChange={() => onCheckTel(tel)}
                  />
                  {errors.mobilePhone &&
                    errors.mobilePhone.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  {errors.mobilePhone &&
                    errors.mobilePhone.type === "pattern" && (
                      <span className="error">Số điện thoại không hợp lệ</span>
                    )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">
                  Email<span className="required"></span>
                </label>
                <div className="col-lg-8">
                  <input
                    ref={register({
                      required: true,
                      pattern: /^[A-Za-z][A-Za-z0-9_\.]{5,32}@[A-Za-z0-9]{2,}(\.[A-Za-z0-9]{2,4}){1,2}$/,
                    })}
                    type="text"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    onChange={handleOnChangeEmail}
                  />
                  {errors.email && errors.email.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <span className="error">Email không đúng định dạng</span>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">
                  Nội dung nhắn<span className="required"></span>
                </label>
                <div className="col-lg-8">
                  <textarea
                    ref={register({ required: true })}
                    className="form-control"
                    name="content"
                    id="validationTextarea"
                    placeholder="Nội dung tin nhắn"
                    rows="5"
                  ></textarea>
                  {errors.content && errors.content.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
              </div>
              <div className="border-top pt-3 text-right">
                <button type="submit" className="btn btn-info pl-3 pr-3">
                  Gửi
                </button>
              </div>
            </form>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-3 mt-md-3 mt-lg-0 mb-3 mb-md-3 mb-lg-0">
            <div style={{ height: "520px", width: "100%" }}>
              {/* <iframe
                  src={viLang.contact_map_url}
                  width="600" height="550" style={{ border: "none" }} allowFullScreen="" loading="lazy"></iframe> */}
              <iframe width="600" height="550" style={{ border: "none" }} allowFullScreen="" loading="lazy"
                // src="https://maps.google.com/maps?width=600&amp;height=550&amp;hl=en&amp;q=Số 01 Vũ Hữu, Thanh Xuân, Hà Nội&amp;t=p&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                  src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Tầng 1, nhà E, Khu Vinaconex 1, số 289A Khuất Duy Tiến, Quận Cầu Giấy, TP. Hà Nội&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
