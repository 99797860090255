import React from 'react';
import './footer.scss';
import viLang from '../../languages/vi';
import { Link } from "react-router-dom";
import ViLanguage from "../../languages/vi";
import UrlCollect from '../../common/url-collect';

const LanguageCollect = ViLanguage;
export default class FooterView extends React.Component {
    render() {
        return (
            <footer className="footer-container">
                <div className='footer-nav-wrapper'>
                    {/* <nav className="main-nav navbar navbar-expand-lg p-0" 
                        style={{background: 'transparent', height: "70px"}}>
                        <div className="container pt-3">
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav" style={{margin: 'auto'}}>
                                    <li>
                                        <Link to="/">
                                            {LanguageCollect.header_homePage}{" "}
                                        </Link>
                                    </li>
                                    <li>
                                        <a href="/y-kien-cong-dong">
                                            {LanguageCollect.header_pleaseConsultTheCommunity}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/gioi-thieu-quy-hoach" >
                                            {LanguageCollect.header_lookUpPlanning} &nbsp;
                                        </a>
                                    </li>
                                    <li className="has-child">
                                        <Link to="/tin-tuc">
                                            Tin tức
                                            &nbsp;
                                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4.99858 7.49952C4.84139 7.49952 4.68423 7.4395 4.56438 7.31972L0.793204 3.5485C0.553307 3.3086 0.553307 2.91965 0.793204 2.67985C1.033 2.44005 1.42188 2.44005 1.66179 2.67985L4.99858 6.01683L8.33539 2.67997C8.57529 2.44017 8.96412 2.44017 9.2039 2.67997C9.44391 2.91977 9.44391 3.30872 9.2039 3.54861L5.43278 7.31983C5.31288 7.43963 5.15571 7.49952 4.99858 7.49952Z"
                                                    fill="white" stroke="white" strokeWidth="0.5"/>
                                            </svg>
                                        </Link>
                                        <ul className="level1">
                                            <li>
                                                <a href="/cong-bo-quy-hoach">
                                                    {LanguageCollect.header_planningAnnouncement}
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/tin-tuc">Tin tức</a>
                                            </li>
                                        </ul>
                                        <div className="cb"></div>
                                    </li>
                                    <li>
                                        <a href="/lien-he">
                                            {LanguageCollect.header_Contact}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav> */}
                </div>
                <div className="info">
                    <div className="container">
                    <div className="row pb-3">
                            <div className="col-lg-6">
                                <div className="name">
                                    <img src="/logo-acud.png" height="60"/>
                                    <span>CÔNG TY CỔ PHẦN CÔNG <br/> nghệ xây dựng ACUD Việt Nam</span>
                                </div>
                                <div className='des'>Đơn vị tư vấn lập quy hoạch</div>
                            </div>
                            <div className="col-lg-6">
                                <div className="name">
                                    <img src="/images/logo-ctech.png" height="60"/>
                                    <span>CÔNG TY CỔ PHẦN CÔNG <br/> NGHỆ CAO CTECH</span>
                                </div>
                                <div className='des'>Đơn vị xây dựng hệ thống thông tin và CSDL về quy hoạch</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="detail">
                                    <p>
                                        <svg width="14" height="14" viewBox="0 0 10 14" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5 0C2.23571 0 0 2.191 0 4.9C0 8.575 5 14 5 14C5 14 10 8.575 10 4.9C10 2.191 7.76429 0 5 0ZM5 6.65C4.01429 6.65 3.21429 5.866 3.21429 4.9C3.21429 3.934 4.01429 3.15 5 3.15C5.98571 3.15 6.78571 3.934 6.78571 4.9C6.78571 5.866 5.98571 6.65 5 6.65Z"
                                                fill="currentColor"/>
                                        </svg>
                                        {viLang.footer_address}
                                    </p>
                                    <p>
                                        <svg width="14" height="14" aria-hidden="true" focusable="false" data-prefix="fas"
                                             data-icon="phone-alt" className="svg-inline--fa fa-phone-alt fa-w-16"
                                             role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor"
                                                  d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
                                        </svg>
                                        {viLang.footer_hotline}
                                    </p>
                                    <p>
                                        <svg width="14" height="14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope"
                                             className="svg-inline--fa fa-envelope fa-w-16" role="img"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor"
                                                  d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path>
                                        </svg>
                                        Email: {viLang.footer_email}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container">
                        {viLang.footer_copyright}
                    </div>
                </div>
            </footer>
        )
    }
}