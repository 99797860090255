import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as homePageStore from "../../../redux/store/home-page/home-page.store";
import * as appActions from "../../../core/app.store";

import "./slider.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
function HomeSliderView(props) {
  const { showLoading } = props;

  const settingSlider = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const [slideShow, setSlideShow] = useState(["./slider-1.jpg","./slider-2.jpg","./slider-3.jpg"]);

  useEffect(() => {
    // onGetData();
  }, []);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      onGetSlideShow(),
    ])
      .then((res) => {
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const onGetSlideShow = () => {
    return new Promise((resolve, reject) => {
      props
        .getSlideShow()
        .then((res) => {
          setSlideShow(res && res.content && res.content.length > 0 ? res.content : []);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };

  return (
      <section id="home-banner" className="banner">
        <div id="slider-home-page" className="slider-home-page">
          <Slider {...settingSlider}>
            {slideShow.map( (item, index) =>
              <div className="home-map" key={index}>
                  <img style={{position: "relative"}} src={require(`${item}`)} alt="Slider image"/>
                  <div style={{position: 'absolute', top: 0, right: '10%'}}>
                    <h1 className="row justify-content-center">
                    <span className="col-12 text-center">HỆ THỐNG THÔNG TIN VÀ CƠ SỞ DỮ LIỆU</span><br/>
                    <span className="col-12 text-center">QUY HOẠCH HẠ TẦNG PHÒNG CHÁY VÀ CHỮA CHÁY</span><br/>
                    <span className="col-12 mini-text text-center">THỜI KỲ 2021-2030, TẦM NHÌN ĐẾN NĂM 2050</span></h1>
                  </div>
              </div>
            )}
          </Slider>
        </div>
      </section>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
      getSlideShow: homePageStore.GetSlideShow,
    },
    dispatch
  );

export default  (connect(mapStateToProps, mapDispatchToProps)(HomeSliderView));
