import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import HomeNewsView from './news/news.view';
import HomeSliderView from "./slider/slider.view";
import HomeSearchView from "./search/search.view"
import HomeLinkView from "./link/link.view"
import HomeAside from "./aside/aside.view"
import "./home.scss";

function HomeDesktop(props) {
  const { showLoading } = props;
  return (
    <div className="home-page">
      <HomeSliderView />
      {/* <HomeLinkView /> */}
      <div className="container">
        <div className="row">
          <div className="home-page-content col-md-12 col-lg-9">
            <HomeLinkView />
            <HomeNewsView />
          </div>
          <div className="col-md-12 col-lg-3">
            <HomeAside/>
          </div>
        </div>
      </div>
      {/* <HomeLinkPlanningView />
      <HomeNewsView /> */}
    </div>
  );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
    },
    dispatch
  );

export default  (connect(mapStateToProps, mapDispatchToProps)(HomeDesktop));
